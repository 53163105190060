import { onMounted, reactive, ref, computed } from "vue";

export interface Episode {
  name: string;
  url: string;
  episodeNumber: string;
}

export function usePodcast() {
  const episodes = reactive<Episode[]>([]);
  type State = "idle" | "pending" | "error" | "resolved";
  const state = ref<State>("idle");

  const displayEpisodes = computed(() => {
    return episodes.slice(1, 10);
  });

  onMounted(async () => {
    try {
      state.value = "pending";
      const podcastXml = await fetch(
        "https://feeds.libsyn.com/302915/rss"
      ).then((b) => b.text());
      state.value = "resolved";

      const parser = new DOMParser();

      const doc = parser.parseFromString(podcastXml, "application/xml");

      doc.querySelectorAll("item").forEach((item) => {
        const title = item.querySelector("title");
        const link = item.querySelector("link");

        if (!link || !title) {
          return;
        }

        const matches = title.innerHTML.match(/Avsnitt\s+(\d+)\:\s+(.*)/);
        const urlMatch = link.innerHTML.match(/\<\!\[CDATA\[(.*)\]\]/);

        if (!urlMatch || !matches) {
          return;
        }

        const [, episodeNumber, episodeName] = matches;

        const [, episodeUrl] = urlMatch;

        episodes.push({
          episodeNumber,
          name: episodeName,
          url: episodeUrl,
        });
      });
    } catch (err) {
      state.value = "error";
      throw err;
    }
  });

  return {
    episodes,
    displayEpisodes,
    state,
  };
}
