import { ref } from "vue";

export function useMenuToggle(initialValue: boolean = false) {
  const state = ref(initialValue);

  return {
    state,
    toggle() {
      state.value = !state.value;
    },
    hide() {
      state.value = false;
    },
  };
}
