<template>
  <a
    :href="episode ? episode.url : 'https://sites.libsyn.com/302915'"
    target="_blank"
    data-template="latest-episode"
  >
    <img
      src="/podcast_small.webp"
      class="h-[70px] w-[70px]"
      width="70"
      height="70"
      alt="Podcast omslag"
    />
    <div class="text-wrapper">
      <h5 v-if="episode">{{ episode.name }}</h5>
      <h5 v-else>Laddar avsnitt...</h5>
      <p class="text-xs">
        Lyssna på senaste avsnittet av föräldrapeppen
        <span class="underline">här</span>
      </p>
    </div>
  </a>
</template>

<script setup lang="ts">
import type { Episode } from "../composables/podcast";
defineProps<{
  episode: Episode | null;
}>();
</script>

<style scoped></style>
