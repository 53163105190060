import type { App } from 'vue';
import * as Sentry from '@sentry/vue';

export default (app: App) => {
  // Initialize Sentry
  Sentry.init({
    app,
    dsn: "https://8ee648af1c67ac36b53449da6102936c@o4508159870435328.ingest.de.sentry.io/4508159878365264",
    tracesSampleRate: 1.0,
  });
};
