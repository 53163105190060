import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://8ee648af1c67ac36b53449da6102936c@o4508159870435328.ingest.de.sentry.io/4508159878365264",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});