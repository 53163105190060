<template>
  <nav
    class="flex items-center font-bold p-4 md:px-0 justify-end gap-8 relative"
    :class="{ 'menu-open': menu.state.value }"
  >
    <a href="/" class="mr-auto">
      <img
        src="/logo_small.svg"
        alt="Föräldrapeppen logo liten"
        class="logo h-[50px] w-auto"
      />
    </a>
    <ul
      class="gap-8 my-8 lg:my-0 lg:flex absolute lg:static bg-header lg:p-0 p-4 top-12 right-0 z-10 hidden"
      @click="handleLinkEvent"
      @keydown.enter="handleLinkEvent"
    >
      <li v-for="[href, text] in menuItems" :key="href">
        <a class="hover:text-primary py-2 lg:text-sm text-lg inline-block lg:inline" :href="href">{{ text }}</a>
      </li>
    </ul>

    <ul class="flex gap-8">
      <li class="flex items-center">
        <a
          target="_blank"
          href="https://www.facebook.com/people/F%C3%B6r%C3%A4ldrapeppen-med-Nette-Gabbe/100030957114248"
          class="hover:text-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            class="w-4 h-4 fill-current"
          >
            <!-- Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path
              d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
            />
          </svg>
        </a>
      </li>
      <li class="flex items-center">
        <a
          target="_blank"
          href="https://www.instagram.com/foraldrapeppen"
          class="hover:text-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="h-4 w-4 fill-current"
          >
            <!-- Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path
              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
            />
          </svg>
        </a>
      </li>
      <li class="flex items-center">
        <a
          target="_blank"
          href="https://www.youtube.com/@Foraldrapeppen"
          class="hover:text-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            class="h-[18px] fill-current"
          >
            <!-- Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path
              d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
            />
          </svg>
        </a>
      </li>
    </ul>
    <button
      ref="menuToggleRef"
      class="lg:hidden text-xl hover:text-primary"
      @click="menu.toggle"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 hamburger"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        ></path>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 x-mark"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  </nav>
</template>

<script setup lang="ts">
import { useClickOutside } from "../composables/click-outside";
import { useMenuToggle } from "../composables/menu-toggle";

const menu = useMenuToggle(false);
const menuToggleRef = useClickOutside(menu.hide);

const menuItems = [
  ["#top", "Hem"],
  ["#om-foraldrapeppen", "Om föräldrapeppen"],
  ["#bok", "Bok"],
  ["#podd", "Podd"],
  ["#forelasningar", "Föreläsningar"],
  ["#kontakt", "Kontakt"],
];

function handleLinkEvent(event: Event) {
  const targetElement = event.target as HTMLElement;

  if (targetElement.tagName !== "A") return;

  const href = targetElement.getAttribute("href");

  if (!href) return;

  const $el = document.querySelector(href);
  $el?.scrollIntoView({ behavior: "smooth" });
  menu.hide();
  event.preventDefault();
}
</script>
