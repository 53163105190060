import { onMounted, onBeforeUnmount, ref } from "vue";

export function useClickOutside(cb: () => void) {
  const domRef = ref<Element | null>(null);

  function clickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!domRef.value?.contains(targetElement)) {
      cb();
    }
  }

  onMounted(() => {
    document.body.addEventListener("click", clickOutside);
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener("click", clickOutside);
  });

  return domRef;
}
