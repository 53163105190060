import type { ContactFormData } from "../types";

export function createEmailTemplate(props: ContactFormData): string {
  return `
  <!DOCTYPE html>
  <html dir="ltr" lang="en">
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type"/>
    <meta name="x-apple-disable-message-reformatting"/>
  </head>
  <body style="background-color:rgb(200,209,211);font-size:1rem;line-height:1.5rem;font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; padding: 20px;">
    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:37.5em">
      <tbody>
        <tr style="width:100%">
          <td>
            <img alt="Logo" src="https://feat-astro.foraldrapeppen.pages.dev/logo.png" style="margin-top:20px;margin-bottom:20px;display:block;outline:none;border:none;text-decoration:none" width="250"/>
          </td>
        </tr>
      </tbody>
    </table>
    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:rgb(255,255,255);padding:45px;max-width:37.5em">
      <tbody>
        <tr style="width:100%">
          <td>
            <h1 style="text-align:center;margin-bottom:3rem;line-height:2rem">Ny kontaktförfråga från peppen.se</h1>
            <p style="font-size:1rem;line-height:1.5rem;margin:16px 0"><strong>${props.name}</strong> vill komma i kontakt med Föräldrapeppen.${props.message && ` ${props.name} lämnade detta meddelandet:`}</p>
            ${
              props.message &&
              `<p style="font-size:1rem;line-height:1.5rem;background-color:rgb(239,239,239);font-style:italic;padding:1rem;border-radius:0.375rem;white-space:pre-wrap;margin:16px 0">${props.message}</p>`
            }
          </td>
        </tr>
      </tbody>
    </table>
    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
      style="text-align:center;padding-top:45px;padding-bottom:45px">
      <tbody>
        <tr>
          <td><a href="mailto:${props.email}"
              style="background-color:rgb(242,160,61);color:rgb(255,255,255);border-radius:0.5rem;padding-top:0.75rem;padding-bottom:0.75rem;padding-left:18px;padding-right:18px;text-decoration:none"
              target="_blank">Svara <!-- --> ${props.name}</a></td>
        </tr>
      </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
      style="margin-top:20px;max-width:37.5em">
      <tbody>
        <tr style="width:100%">
          <td>
            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
              <tbody>
                <tr>
                  <td>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                      <tbody style="width:100%">
                        <tr style="width:100%">
                          <td data-id="__react-email-column" style="text-align:center;padding-left:20px;padding-right:20px">
                            <a href="https://peppen.se" style="color:#067df7;text-decoration:none"
                              target="_blank">Föräldrapeppen</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style="text-align:center;color:rgb(156,163,175);margin-bottom:45px;font-size:14px;line-height:24px;margin:16px 0; padding: 0 20px">
              Föräldrapeppen vill ge resurser kring relationer, föräldraskap och tro. Högt och lågt, skratt och
              allvar i en salig blanding. Precis som livet är!</p>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
  </html>
  `;
}
