<template>
  <form
    id="contact-form"
    class="flex flex-col gap-4 my-4 text-left"
    @submit.prevent="sendEmail"
  >
    
    <div class="grid grid-cols-2 gap-4">
      <input
        name="name"
        type="text"
        placeholder="Namn"
        required
        class="rounded-md bg-gray-100 p-4"
      />
      <input
        name="email"
        type="email"
        placeholder="Mailadress"
        required
        class="rounded-md bg-gray-100 p-4"
      />
    </div>
    
    <textarea
      name="message"
      placeholder="Meddelande"
      class="rounded-md bg-gray-100 p-4 mt-4"
    ></textarea>
    <div>
      <span v-if="state === 'loading'" class="text-primary font-semibold">Skickar mail...</span>
      <span v-else-if="state === 'success'" class="text-green-700 font-semibold">Vi hör av oss så snart vi kan!</span>
      <span v-else-if="state === 'error'" class="text-red-700 font-semibold">Något gick fel, försök igen senare. Dubbelkolla att din mailadress är korrekt.</span>
      <button v-else class="bg-primary text-white font-semibold px-12 py-2 rounded-md">
        Skicka mail
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import ky from "ky";
import type { ContactFormData } from "../types/index.ts";
import { ref } from "vue";
import { createEmailTemplate } from "../emails/ContactEmail.tsx"
const functionBase = import.meta.env.PUBLIC_FUNCTION_BASE || "";

const state = ref<"idle" | "loading" | "success" | "error">("idle");

function validateFields(formData: FormData): ContactFormData {
  return {
    name: formData.get("name") as string,
    email: formData.get("email") as string,
    message: (formData.get("message") || "") as string,
  };
}

async function sendEmail(event: Event) {
  event.preventDefault()
  const target = event.target as HTMLFormElement;
  const formData = new FormData(target);
  
  const contactData = validateFields(formData);
  
  const template = createEmailTemplate(contactData)
  
  
  try {
    state.value = 'loading'

    await ky.post(functionBase + "/api/send-mail", {
      json: {
        email: template,
      },
    })
    .json();
    
    state.value = 'success'

    target.reset()
  } catch (err: any) {
    state.value = 'error'
    throw err
  } finally {
    setTimeout(() => {
      state.value = 'idle'
    }, 5000)
  }
}
</script>
