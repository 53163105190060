<template>
  <div
    class="flex-1 lg:border-r-2 border-gray-200 space-y-6 lg:pr-8 lg:mr-8 mt-4 lg:mt-0"
  >
    <LatestEpisode :episode="latestEpisode" />
    <h2 class="border-b-2">TIDIGARE AVSNITT</h2>
    <ul v-if="state === 'resolved'" class="space-y-2">
      <li
        class="bg-gray-200 p-2 rounded-sm"
        v-for="episode in displayEpisodes"
        :key="episode.episodeNumber"
      >
        <a :href="episode.url" target="_blank">
          {{ episode.episodeNumber }}. {{ episode.name }}
        </a>
      </li>
    </ul>

    <ul class="space-y-2" v-else>
      <li v-for="_ in 9" class="bg-gray-200 p-2 animate-pulse rounded-sm">
        <span class="inline-block bg-gray-400 w-3 h-3 mr-4 rounded-full"></span
        ><span
          class="inline-block bg-gray-400 w-48 h-3 mr-4 rounded-full"
        ></span>
      </li>
    </ul>

    <!-- only rendered on the client to avoid hydration mismatch -->
    <teleport v-if="isClient" to="#sticky-episode">
      <LatestEpisode
        :episode="latestEpisode"
        class="fixed top-3/4 right-0 inline-flex m-8 w-max max-w-[80%] bg-primary after:bg-primary"
        :class="{
          'translate-x-[calc(100%-70px)]': !latestEpisode || hideStickyEpisode,
          'opacity-0': !latestEpisode,
        }"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { usePodcast, type Episode } from "../composables/podcast";
import LatestEpisode from "./LatestEpisode.vue";

const isClient = ref(false);
const { displayEpisodes, episodes, state } = usePodcast();
const latestEpisode = ref<Episode | null>(null);
const hideStickyEpisode = ref<Boolean>(false);

onMounted(async () => {
  isClient.value = true;
});

watch(state, (currentState) => {
  if (currentState === "resolved" && episodes.length) {
    setTimeout(() => {
      latestEpisode.value = episodes[0];

      setTimeout(() => {
        hideStickyEpisode.value = true;
      }, 6000);
    }, 2000);
  }
});
</script>
